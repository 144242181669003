import { Layout } from '@components/layout';
import { PageHeader } from '@components/shared';
import {
  AboutFeaturedText,
  AboutHero,
  AboutGallery,
  AboutFeaturedTextSecond
} from '@components/standard';
import type { IPage, ILocation } from '@standardTypes';
import React from 'react';

interface AboutPageProps {
  pageContext: {
    page: IPage;
  };
  location: ILocation;
}

const AboutPage: React.FC<AboutPageProps> = ({ pageContext }) => {
  const { page } = pageContext;

  return (
    <Layout {...page}>
      <PageHeader title="O nas" />
      <AboutHero />
      <AboutFeaturedText />
      <AboutGallery />
      <AboutFeaturedTextSecond />
    </Layout>
  );
};

export default AboutPage;
